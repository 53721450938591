import React from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
//@material-ui/icons
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "assets/jss/components/views/questStyle";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function SimpleDialogDemo(props) {
  let questList = Array.isArray(props.data.questList)
    ? props.data.questList.sort((a, b) => a.userQuestId - b.userQuestId)
    : props.data.questList;

  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogTitle id="simple-dialog-title" onClose={props.onClose}>
        <Avatar variant="square" className={classes.square} src={props.logo} />
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <List>
          {questList.map((value, indx) => (
            <ListItem
              button
              onClick={() => props.onChange(value)}
              key={value.questId + "_IND" + indx}
            >
              <ListItemAvatar className={classes.listItemAvatarStyle}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant={
                    props.activeData.questId === value.questId ? "dot" : null
                  }
                >
                  <Avatar variant="square" className={classes.avatar}>
                    <ImportContactsIcon />
                  </Avatar>
                </StyledBadge>
              </ListItemAvatar>
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography className={classes.heading}>
                    {value.questName}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </React.Fragment>
  );
}
